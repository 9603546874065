//created by Peggy on 2021/5/22
import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Breadcrumb } from "antd";

import {
  Document,
  Page,
  PDFDownloadLink,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { contributionDetail } from "@/adminApp/apis/finance-api";

import { useModel } from "use-reaction";
import { view } from "@/model/view";

import logo from "@/images/signature-logo.png";
import signature from "@/images/signature_cb.png";

import styled from "styled-components";
const localizedFormat = require("dayjs/plugin/localizedFormat");
const dayjs = require("dayjs");
dayjs.extend(localizedFormat);
const NoteContainer = styled.div`
  h1 {
    margin-bottom: 40px;
  }
  .card-wrap {
    flex: 1;
    padding: 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }
`;

const TemplateContainer = styled.div`
  span {
    display: block;
    width: 100%;
  }
  h3,
  h4 {
    font-size: 16px;
    font-weight: 900 !important;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    color: #333;
    margin-bottom: 16px;
  }
`;

const styles = StyleSheet.create({
  body: {
    display: "flex",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 0,
    paddingHorizontal: 35,
    fontSize: 12,
    fontWeight: "thin",
  },
  title: {
    display: "flex",
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 18,
  },
  subtitle: {
    display: "flex",
    lineHeight: 1.8,
    fontWeight: 700,
  },

  author: {
    display: "flex",
    lineHeight: 1.8,
    fontWeight: 700,
    margin: "20px 0 20px 0",
  },

  text: {
    display: "flex",
    width: "100%",
    margin: "0 0 24px 0",
    textAlign: "justify",
    lineHeight: 1.5,
    fontWeight: "thin",
    color: "#333",
  },

  subtext: {
    display: "flex",
    width: "100%",
    lineHeight: 1.5,
    fontWeight: 500,
    color: "#333",
  },
  images: {
    width: 150,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  header_logo: {
    width: '100%',
  },
  header_view: {
    marginLeft: 20,
  },
});

export const MyDocument = React.memo(({ user }: any) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <Image style={styles.header_logo} src={logo}></Image>
      </View>
      <Text style={styles.title}>{dayjs(user?.createDate).format("LL")}</Text>
      <Text style={styles.subtitle}>{user?.issuedTo?.name}</Text>
      <Text style={styles.subtitle}>
        {user?.issuedTo?.addressInfo?.address}
      </Text>
      <Text style={styles.subtitle}>
        {[
          user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.addressInfo?.state,
        ].filter(Boolean).join(", ") + ' ' + user?.issuedTo?.addressInfo?.zipCode}
      </Text>
      <Text style={styles.author}>{`Dear ${user?.issuedTo?.name},`}</Text>
      <Text style={styles.text}>
        {`Thank you for your generous contribution of ${Number(
          user?.amount
        ).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })} to The Field on behalf of Sponsored Artist ${user?.artist?.displayName || user?.artist?.name
          }'s campaign, ${user?.crowdfunding?.name
          }. This contribution was recorded as Order ID ${user?.orderId
          } received on ${dayjs(user?.createDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )}, and is very much appreciated.`}
      </Text>
      <Text style={styles.text}>
        {`We’re glad that you were able to support ${user?.artist?.displayName || user?.artist?.name
          }. As a not-for-profit organization, The Field's Sponsored Artist Program is just one of the many services we offer independent performing artists and companies to help make their artistic and career goals a reality. Each year, our artists create and share new works with a worldwide audience of hundreds of thousands of art-lovers like you!`}
      </Text>
      <Text style={styles.text}>
        Please save this message for your tax records and consult your
        accountant for details regarding the deductibility of this donation.
        Please note that we are incorporated as The Performance Zone Inc., DBA The Field, a 501(c)(3) organization; our FEIN is 13-3357408.
      </Text>
      <Text style={styles.text}>
        {`Thank you, again, for supporting ${user?.artist?.displayName || user?.artist?.name
          } and keeping the arts growing and thriving with your generous donation.`}
      </Text>
      <Text style={styles.subtext}>Best Wishes,</Text>
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: 16,
          marginTop: 12,
        }}
      >
        <Image style={styles.images} src={signature} />
      </View>
      <Text style={{ marginBottom: 4 }}>Christopher D. Bloodworth</Text>
      <Text style={{ marginBottom: 4 }}>Executive Director, The Field</Text>
      <Text style={{ marginBottom: 4 }}>212-691-6969</Text>
      <Text style={styles.text}>fiscalsponsorship@thefield.org</Text>
      <Text style={styles.text}>
        P.S. This letter acknowledges your contribution is tax-deductible to the
        fullest extent allowed by law and that no goods or services were
        exchanged.
      </Text>
    </Page>
  </Document>
));

export const ArtistDocument = React.memo(({ user }: any) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <Image style={styles.header_logo} src={logo}></Image>
      </View>
      <Text style={{...styles.title,marginTop:10}}>{dayjs(user?.createDate).format("LL")}</Text>
      <Text style={styles.subtitle}>{user?.issuedTo?.name}</Text>
      <Text style={styles.subtitle}>
        {user?.issuedTo?.addressInfo?.address}
      </Text>
      <Text style={styles.subtitle}>
        {[
          user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.addressInfo?.state,
        ].filter(Boolean).join(", ") + ' ' + user?.issuedTo?.addressInfo?.zipCode}
      </Text>
      <Text style={styles.author}>{`Dear ${user?.issuedTo?.name},`}</Text>
      <Text style={styles.text}>
        {`Thank you for your generous contribution of ${Number(
          user?.amount
        ).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })} to The Field on behalf of our Sponsored Artist, ${user?.artist?.displayName || user?.artist?.name
          }. This contribution was recorded as Order ID ${user?.orderId
          } received on ${dayjs(user?.createDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )}, and is very much appreciated.`}
      </Text>
      <Text style={styles.text}>
        {`We’re glad that you were able to support ${user?.artist?.displayName || user?.artist?.name
          }. As a not-for-profit organization, The Field's Sponsored Artist Program is just one of the many services we offer independent artists and companies to help make their artistic and career goals a reality. Each year, our artists create and share new works with a worldwide audience of hundreds of thousands of art-lovers like you! `}
      </Text>
      <Text style={styles.text}>
        Please save this message for your tax records and consult your
        accountant for details regarding the deductibility of this donation.
        Please note that we are incorporated as The Performance Zone Inc., DBA The Field, a 501(c)(3) organization; our FEIN is 13-3357408.
      </Text>
      <Text style={styles.text}>
        {`Thank you, again, for supporting ${user?.artist?.displayName || user?.artist?.name
          } and keeping the arts growing and thriving with your generous donation.`}
      </Text>
      <Text style={styles.subtext}>Best Wishes,</Text>
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: 16,
          marginTop: 12,
        }}
      >
        <Image style={styles.images} src={signature} />
      </View>
      <Text style={{ marginBottom: 4 }}>Christopher D. Bloodworth</Text>
      <Text style={{ marginBottom: 4 }}>Executive Director, The Field</Text>
      <Text style={{ marginBottom: 4 }}>212-691-6969</Text>
      <Text style={styles.text}>fiscalsponsorship@thefield.org</Text>
      <Text style={styles.text}>
        P.S. This letter acknowledges your contribution is tax-deductible to the
        fullest extent allowed by law and that no goods or services were
        exchanged.
      </Text>
    </Page>
  </Document>
));

export const TheFieldDocument = React.memo(({ user }: any) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <Image style={styles.header_logo} src={logo}></Image>
      </View>
      <Text style={styles.title}>{dayjs(user?.createDate).format("LL")}</Text>
      <Text style={styles.subtitle}>{user?.issuedTo?.name}</Text>
      <Text style={styles.subtitle}>
        {user?.issuedTo?.addressInfo?.address}
      </Text>
      <Text style={styles.subtitle}>
        {[
          user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.addressInfo?.state,
        ].join(", ") + ' ' + user?.issuedTo?.addressInfo?.zipCode}
      </Text>
      <Text style={styles.author}>{`Dear ${user?.issuedTo?.name},`}</Text>
      <Text style={styles.text}>
        {`On behalf of The Field, thank you so much for your generous contribution of ${Number(
          user?.amount
        ).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })}. This contribution was recorded as Order ID ${user?.orderId
          } received on ${dayjs(user?.createDate).format(
            "MM/DD/YYYY"
          )}, and is very much appreciated. `}
      </Text>
      <Text style={styles.text}>
        This letter acknowledges your contribution is tax-deductible to the
        fullest extent allowed by law and that no goods or services were
        exchanged. Please save this message for your tax records and consult
        your accountant for details regarding the deductibility of this
        donation. Please note that we are incorporated as The Performance Zone
        Inc., DBA The Field, a 501(c)(3) organization; our FEIN is 13-3357408.
      </Text>
      <Text style={styles.text}>
        Your generous donation helps provide support and resources to our
        vibrant community of artists. Thank you for being a vital part of that
        support system.
      </Text>
      <Text style={styles.subtext}>Best Wishes,</Text>
      <View
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: 16,
          marginTop: 12,
        }}
      >
        <Image style={styles.images} src={signature} />
      </View>
      <Text style={{ marginBottom: 4 }}>Christopher Bloodworth</Text>
      <Text>Executive Director</Text>
      <Text>The Field</Text>
      <Text>228 Park Ave S, Suite 97217</Text>
      <Text>New York, NY 10003</Text>
      <Text>thefield.org | 212-691-6969</Text>
    </Page>
  </Document>
));

export const DownloadPdf = (props: any) => {
  return useMemo(
    () =>
      (props?.user && (
        <PDFDownloadLink
          document={
            props?.user?.type === "donation_to_artist" ? (
              <ArtistDocument {...props} />
            ) : props?.user?.type === "donation_to_the_field" || props?.user?.type === "donation_to_the_field_crowd" ? (
              <TheFieldDocument {...props} />
            ) : (
              <MyDocument {...props} />
            )
          }
          fileName={`Thank you for your contribution to ${props?.artist?.name ?? "The Field"
            }.pdf`}
          style={props.isMobile ? { width: "100%" } : {}}
        >
          {({ loading }) => (
            <Button
              type="primary"
              style={props.isMobile ? { width: "100%" } : {}}
            >
              {loading ? "loading..." : "Save as pdf"}
            </Button>
          )}
        </PDFDownloadLink>
      )) ||
      "",
    // eslint-disable-next-line
    [props.user]
  );
};

export const Template = ({ user }: any) => (
  <TemplateContainer>
    <h2>Thank you for your contribution to {user?.crowdfunding?.name} !</h2>
    <h3>
      <span>{user?.issuedTo?.ty_name || user?.issuedTo?.name}</span>
      <span>{user?.issuedTo?.ty_address || user?.issuedTo?.addressInfo?.address}</span>
      <span>
        {[
          user?.issuedTo?.ty_city || user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.ty_state || user?.issuedTo?.addressInfo?.state,
        ].filter(Boolean).join(", ")}
        &nbsp;{user?.issuedTo?.ty_zipCode || user?.issuedTo?.addressInfo?.zipCode}
      </span>
    </h3>
    <h4>{`Dear ${user?.issuedTo?.ty_name || user?.issuedTo?.name},`}</h4>
    <p>
      Thank you for your generous contribution of{" "}
      <b>
        {Number(user?.amount).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })}{" "}
      </b>{" "}
      to The Field on behalf of Sponsored Artist{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b>'s campaign,{" "}
      <b>{user?.crowdfunding?.name}</b>. This contribution was recorded as Order
      ID <b>{user?.orderId}</b> received on{" "}
      <b>{dayjs(user?.createDate).format("YYYY-MM-DD HH:mm:ss")}</b>, and is
      very much appreciated.
    </p>
    <p>
      We’re glad that you were able to support{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b>. As a
      not-for-profit organization, The Field's Sponsored Artist Program is just
      one of the many services we offer independent artists and companies to
      help make their artistic and career goals a reality. Each year, our
      artists create and share new works with a worldwide audience of hundreds
      of thousands of art-lovers like you!
    </p>
    <p>
      Please save this message for your tax records and consult your accountant
      for details regarding the deductibility of this donation. Please note that
      we are incorporated as The Performance Zone Inc., DBA The Field, a
      501(c)(3) organization; our FEIN is 13-3357408.
    </p>
    <p>
      Thank you, again, for supporting{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b> and keeping the
      arts growing and thriving with your generous donation.
    </p>
    <p>
      <span>Best Wishes,</span>
      <img alt="" style={{marginTop:10,marginBottom:10}}  src={signature} />
      <span>Christopher D. Bloodworth</span>
      <span>Executive Director, The Field</span>
      <span>212-691-6969</span>
      <span>fiscalsponsorship@thefield.org</span>
    </p>
    <p>
      P.S. This letter acknowledges your contribution is tax-deductible to the
      fullest extent allowed by law and that no goods or services were
      exchanged.
    </p>
  </TemplateContainer>
);

export const ArtistTemplate = ({ user }: any) => (
  <TemplateContainer>
    <h2>
      Thank you for your contribution to{" "}
      {user?.artist?.displayName || user?.artist?.name} !
    </h2>
    <h3>
      <span>{user?.issuedTo?.ty_name || user?.issuedTo?.name}</span>
      <span>{user?.issuedTo?.ty_address || user?.issuedTo?.addressInfo?.address}</span>
      <span>
        {[
          user?.issuedTo?.ty_city || user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.ty_state || user?.issuedTo?.addressInfo?.state,
        ].filter(Boolean).join(", ")}
        &nbsp;{user?.issuedTo?.ty_zipCode || user?.issuedTo?.addressInfo?.zipCode}
      </span>
    </h3>
    <h4>{`Dear ${user?.issuedTo?.ty_name || user?.issuedTo?.name},`}</h4>
    <p>
      Thank you for your generous contribution of{" "}
      <b>
        {Number(user?.amount).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })}{" "}
      </b>
      to The Field on behalf of our Sponsored Artist,{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b>. This
      contribution was recorded as Order ID <b>{user?.orderId}</b> received on{" "}
      <b>{dayjs(user?.createDate).format("YYYY-MM-DD HH:mm:ss")}</b>, and is
      very much appreciated.
    </p>
    <p>
      We’re glad that you were able to support{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b>. As a
      not-for-profit organization, The Field's Sponsored Artist Program is just
      one of the many services we offer independent artists and companies to
      help make their artistic and career goals a reality. Each year, our
      artists create and share new works with a worldwide audience of hundreds
      of thousands of art-lovers like you!
    </p>
    <p>
      Please save this message for your tax records and consult your accountant
      for details regarding the deductibility of this donation. Please note that
      we are incorporated as The Performance Zone Inc., DBA The Field, a
      501(c)(3) organization; our FEIN is 13-3357408.
    </p>
    <p>
      Thank you, again, for supporting{" "}
      <b>{user?.artist?.displayName || user?.artist?.name}</b> and keeping the
      arts growing and thriving with your generous donation.
    </p>
    <p>
      <span>Best Wishes,</span>
      <img alt="" style={{marginTop:10,marginBottom:10}} src={signature} />
      <span>Christopher D. Bloodworth</span>
      <span>Executive Director, The Field</span>
      <span>212-691-6969</span>
      <span>fiscalsponsorship@thefield.org</span>
    </p>
    <p>
      P.S. This letter acknowledges your contribution is tax-deductible to the
      fullest extent allowed by law and that no goods or services were
      exchanged.
    </p>
  </TemplateContainer>
);
export const TheFieldTemplate = ({ user }: any) => (
  <TemplateContainer>
    <h2>Thank You from The Field!</h2>
    <h3>
      <span>{user?.issuedTo?.ty_name || user?.issuedTo?.name}</span>
      <span>{user?.issuedTo?.ty_address || user?.issuedTo?.addressInfo?.address}</span>
      <span>
        {[
          user?.issuedTo?.ty_city || user?.issuedTo?.addressInfo?.city,
          user?.issuedTo?.ty_state || user?.issuedTo?.addressInfo?.state,
        ].filter(Boolean).join(", ")}
        &nbsp;{user?.issuedTo?.ty_zipCode || user?.issuedTo?.addressInfo?.zipCode}
      </span>
    </h3>
    <h4>{`Dear ${user?.issuedTo?.ty_name || user?.issuedTo?.name},`}</h4>
    <p>
      On behalf of The Field, thank you so much for your generous contribution
      of{" "}
      <b>
        {Number(user?.amount).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })}{". "}
      </b>
      This contribution was recorded as Order ID <b>{user?.orderId}</b> received
      on <b>{dayjs(user?.createDate).format("MM/DD/YYYY")}</b>, and is
      very much appreciated.
    </p>
    <p>
      This letter acknowledges your contribution is tax-deductible to the
      fullest extent allowed by law and that no goods or services were
      exchanged. Please save this message for your tax records and consult your
      accountant for details regarding the deductibility of this donation.
      Please note that we are incorporated as The Performance Zone Inc., DBA The
      Field, a 501(c)(3) organization; our FEIN is 13-3357408.
    </p>
    <p>
      Your generous donation helps provide support and resources to our vibrant
      community of artists. Thank you for being a vital part of that support
      system.
    </p>
    <p>
      <span>Best Wishes,</span>
      <img style={{marginTop:10,marginBottom:10}} alt="" src={signature} />
      <span>Christopher Bloodwort</span>
      <span>Executive Director </span>
      <span>The Field</span>
      <span>228 Park Ave S, Suite 97217</span>
      <span>New York, NY 10003</span>
      <span>thefield.org | 212-691-6969</span>
    </p>
  </TemplateContainer>
);

const Note = () => {
  const history = useHistory();
  let { id } = useParams() as any;
  const [info, setInfo] = useState<any>();
  const {
    store: { isMobile },
  } = useModel(view);
  useEffect(() => {
    contributionDetail(id).then((data) => setInfo(data));
    // eslint-disable-next-line
  }, []);
  return (
    <NoteContainer className="note-container">
      <Breadcrumb separator=">">
        {history.location.pathname.indexOf("/dashboard/history/") > -1 ? (
          <Breadcrumb.Item href="/dashboard/history/">
            {isMobile ? "" : "Donation history"}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item href="/dashboard/income">
            {isMobile ? "" : "Income Overview"}
          </Breadcrumb.Item>
        )}

        <Breadcrumb.Item
          // href={
          //   history.location.pathname.indexOf("/dashboard/history/") > -1
          //     ? `/dashboard/history/${id}`
          //     : `/finance/contribution/${id}`
          // }
          onClick={(e) => history.goBack()}
        >
          Order details
        </Breadcrumb.Item>
        <Breadcrumb.Item>Thank you note</Breadcrumb.Item>
      </Breadcrumb>
      <div className="note-wrap">
        <h1>Thank You Note</h1>
        <div className="content-wrap card-wrap">
          {info?.type === "donation_to_artist" ? (
            <ArtistTemplate user={info} />
          ) : info?.type === "donation_to_the_field" ? (
            <TheFieldTemplate user={info} />
          ) : (
            <Template user={info} />
          )}
        </div>
        <div className="flex end">
          <DownloadPdf user={info} isMobile={isMobile} />
        </div>
      </div>
    </NoteContainer>
  );
};

export default Note;
