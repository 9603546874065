import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import DatePicker from "@/components/DatePicker";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { ReactComponent as Filter } from "@/images/filter.svg";

import useConfirm from "@/components/Modal/Confirm";

import {
  userList,
  userAccess,
  userTag,
  userNote,
  userSuspend,
  userDelete,
  userAdd,
  getUserTag,
  userEditTag,
  userEditEmail,
  userBulkTag,
  userTagDelete,
  userTagUpdate,
} from "./../../apis/user-api";

import "./admin_users.less";
import queryString from "query-string";
import { admin_router_message_to } from "@/adminApp/routers";
import { getEnv } from "@/libs/cfg";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
import { UserRole } from "@/pages/dashboard/myMsg/myMsg";

export const roles = [
  {
    label: "Artist",
    value: "artist",
  },
  {
    label: "Donor",
    value: "donor",
  },
  {
    label: "Admin",
    value: "administrator",
  },
];

export const AdminUsers = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [editingEmail, setEditingEmail] = useState("");

  const [windowHeight] = useWindowSize();

  const { setShow, component } = useConfirm(
    <div className="flex column start align-start">
      <p>You are suspending this account By clicking ‘Suspend’, you will:</p>
      <ul>
        <li>Cancel all subscriptions </li>
        <li>Suspend this account </li>
        <li>
          Unpublish all content from this user, including artist profile,
          sponsor artist contract, all active events and campaigns
        </li>
        <li>Disable this user from loging in </li>
        <li>Back up all content in the database.</li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to suspend this account ? ",
      className: "suspend-confirm-modal",
      okText: "suspend",
      onOk: (id?: any) => {
        userSuspend({
          id,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );
  const { setShow: setShowEditEmail, component: editEmailComp } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are changing the login email for this user. After you click SAVE,
        the system will send a password reset request to the new email address.
        Please ensure all information is correct before proceeding.
      </p>
      <div className="field-form-items">
        <span>email</span>
        <Input
          size="large"
          placeholder="Enter Email"
          value={editingEmail}
          onChange={(e) =>
            setEditingEmail(e.target.value.trim().toLocaleLowerCase())
          }
        />
      </div>
    </div>,
    {
      title: "Edit email address",
      className: "suspend-confirm-modal",
      okText: "save",
      onOk: (id?: any) => {
        setEditingEmail("");
        if (
          !editingEmail ||
          !/^(?![_.-])[A-Za-z0-9._%+-]+(?<![_.-])@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.exec(
            editingEmail
          )
        ) {
          message.error("invalid email");
          return;
        }
        userEditEmail({
          id,
          email: editingEmail,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are about to permantly delete this account. <br /> By clicking
        ‘Delete’, you will:
      </p>
      <ul>
        <li>Cancel all subscriptions </li>
        <li>Delete this account </li>
        <li>Delete all content related to this account</li>
        <li>Disable this user from loging in </li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to permanently delete this account ?  ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        userDelete({
          id,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, date, ...others } = params;
    const [startDate, endDate] = date || [null, null];
    let _data = {
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      ...others,
    };
    window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/user/export-csv?${queryString.stringify({
        ..._data,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess(),
      })}`,
      "_blank"
    );
  };

  // -add user
  const [userForm] = Form.useForm();
  const [userModal, setUserModal] = useState(false);
  // const handleShowUserModal = () => {
  //   setUserModal(true);
  // };
  const handleUserFinish = (values: any) => {
    setLoading(true);
    userAdd(values).then((data) => {
      userForm.resetFields();
      setUserModal(false);
      getList();
      message.success("add successfully");
      setLoading(false);
    });
  };
  // -add tag
  const [tagForm] = Form.useForm();
  const [tagList, setTagList] = useState<any[]>([]);
  const [tagModal, setTagModal] = useState<object | boolean>(false);
  const [bulkTagForm] = Form.useForm();
  const [bulkTagModal, setBulkTagModal] = useState<object | boolean>(false);
  const getTags = () => {
    getUserTag().then((data) => setTagList(data));
  };
  const handleShowTagModal = (row: any) => {
    setTagModal(row);
    if (row !== true) {
      const { id, tags = "" } = row;
      tagForm.setFieldsValue({
        id,
        tags: tags || [],
      });
    }
  };
  const handleTagFinish = (values: any) => {
    setLoading(true);
    if (tagModal === true) {
      userTag(values)
        .then((data) => {
          message.success("Add successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getTags();
        })
        .catch(() => setLoading(false));
    } else {
      userEditTag(values)
        .then(() => {
          message.success("Save successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getList();
        })
        .catch(() => setLoading(false));
    }
  };

  const handleBulkTagFinish = async (values: any) => {
    setLoading(true);
    const res = await userBulkTag({
      ...values,
      ids: selectedRows.map(({ id }: any) => id),
    });
    if (res?.success) {
      message.success("Assign successfully");
    }
    bulkTagForm.resetFields();
    setLoading(false);
    setBulkTagModal(false);
    getList();
  };
  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };
  const handleFilterFinish = (values: any) => {
    setSelectedRows([]);
    setTableData([]);
    setParams({
      ...params,
      ...values,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  useEffect(() => {
    getTags();
  }, []);
  // -view note
  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(false);
  const handleViewNote = (row: any) => {
    setNoteModal(true);
    const { roles, ...others } = row;
    noteForm.setFieldsValue({ ...others, roles: roles[0] });
  };
  const handleNoteFinish = (values: any) => {
    const { id, note } = values;
    userNote({
      id,
      note,
    }).then((data) => {
      setNoteModal(false);
      getList();
      message.success("successfully");
    });
  };
  const handleAccessToUser = (id: number) => {
    userAccess({ id }).then((data) => {
      window.open(`${getEnv("WEB_HOST")}/auth/${data.token}`, "_blank");
    });
  };

  const handleSuspend = (row: any) => {
    setShow(row.id);
  };
  const handleDelete = (row: any) => {
    deleteShow(row.id);
  };

  const handleEditEmail = (row: any) => {
    setShowEditEmail(row.id);
  };

  //handler end

  const column: any[] = [
    {
      title: "User name",
      width: 130,
      dataIndex: "name",
    },
    {
      title: "User role",
      width: 130,
      dataIndex: "roles",
      className: "artist-cell-roles",
      render: (text: any[], _: any) =>
        text
          ?.filter((item) => item !== UserRole.SUPER_ADMIN)
          .map((item) => (
            <Tag
              color={
                item === "artist"
                  ? "green"
                  : item === "donor"
                  ? "purple"
                  : "blue"
              }
            >
              {item}
            </Tag>
          )),
    },
    {
      title: "user tag",
      dataIndex: "tags",
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: any[], _: any) => text?.join(",") || "N/A",
    },
    {
      title: "Email address",
      dataIndex: "email",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Phone number",
      width: 150,
      dataIndex: "phone",
    },
    {
      title: "date created",
      dataIndex: "createDate",
      width: 130,
      key: "createDate",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <div style={{ padding: 8 }}>
                Note: Expired accounts and crowdfunding campaigns may remain
                Active for 1-2 days after a failed payment attempt.
              </div>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      width: 110,
      key: "status",
      render: (text: string, row: any) => (
        <Tag color={text === "active" ? "green" : "orange"}>{text}</Tag>
      ),
    },
    {
      title: "",
      width: 80,
      align: "center",
      key: "action",
      fixed: "right",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                {(row.progress >= 4 || row.roles?.includes("donor")) && (
                  <Menu.Item onClick={() => handleAccessToUser(row.id)}>
                    Access account
                  </Menu.Item>
                )}

                <Menu.Item
                  key="msg-i"
                  onClick={() =>
                    history.push(admin_router_message_to.replace(":to", row.id))
                  }
                >
                  Message
                </Menu.Item>
                <Menu.Item key="note-i" onClick={() => handleViewNote(row)}>
                  View admin note
                </Menu.Item>
                <Menu.Item key="tag-i" onClick={() => handleShowTagModal(row)}>
                  Edit user tag
                </Menu.Item>
                {!row.roles?.includes(UserRole.SUPER_ADMIN) && (
                  <Menu.Item key="sus-i" onClick={() => handleSuspend(row)}>
                    Suspend account
                  </Menu.Item>
                )}
                <Menu.Item key="email-i" onClick={() => handleEditEmail(row)}>
                  Edit email address
                </Menu.Item>
                {row.status === "suspended" && (
                  <Menu.Item key="del-i" onClick={() => handleDelete(row)}>
                    Delete account
                  </Menu.Item>
                )}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    note: "",
  });
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const getList = () => {
    const { date, ...others } = params,
      [startDate, endDate] = date || [null, null];
    let _data = {
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      ...others,
    };
    userList(_data)
      .then((data) => {
        setTableData(data.items);
        setTotal(data.meta.totalItems);
      })
      .catch(() => {
        setTableData([]);
        setTotal(0);
      });
  };

  const [editingTag, setEditingTag] = useState<{
    id: number;
    name: string;
    del: boolean;
  }>();
  const saveEditTag = async () => {
    if (!editingTag) {
      return;
    }
    if (editingTag.del) {
      const res = await userTagDelete(editingTag!.id);
      if (res?.success) {
        message.success("Delete Successfully");
        getTags();
      }
    } else {
      const res = await userTagUpdate(editingTag.id, editingTag.name);
      if (res?.success) {
        message.success("Save Successfully");
        getTags();
      }
    }
    setEditingTag(undefined);
    getList();
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-users-container admin-common-container">
      <h1>Users</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              value={params?.date}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>user role</span>
            <Select
              allowClear
              bordered={false}
              placeholder="Select a role "
              mode="multiple"
              value={params?.role}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  role: e,
                });
              }}
            >
              {roles.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>suspended</span>
            <Select
              allowClear
              bordered={false}
              placeholder="Whether suspended "
              mode="multiple"
              value={params?.status}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  status: e,
                });
              }}
            >
              <Select.Option key="active" value="active">
                No
              </Select.Option>
              <Select.Option key="suspended" value="suspended">
                Yes
              </Select.Option>
            </Select>
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              {/*<Menu.Item onClick={handleShowUserModal}>Add new user</Menu.Item>*/}
              <Menu.Item onClick={() => handleShowTagModal(true)}>
                Add new user tag
              </Menu.Item>
              <Menu.Item
                disabled={!selectedRows?.length}
                onClick={() => setBulkTagModal(true)}
              >
                Assign user tag
              </Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>User</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: "max-content",
          y: windowHeight - 420,
        }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Add new user"
        className="common-modal admin-user-modal"
        visible={userModal}
        onOk={() => userForm.submit()}
        okText="add"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setUserModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={userForm}
          onFinish={handleUserFinish}
          layout="vertical"
          name="form_user"
        >
          <Form.Item
            label="User role"
            name="role"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "User role",
              },
            ]}
          >
            <Select allowClear bordered={false} placeholder="Select a role ">
              {roles.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "First name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="First name" />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "last name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Last name" />
          </Form.Item>

          <Form.Item
            label="email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "email",
              },
            ]}
          >
            <Input maxLength={100} placeholder="Email" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title={tagModal === true ? "Add new user tag" : "Edit user tag"}
        className="common-modal admin-user-modal"
        visible={!!tagModal}
        onOk={() => tagForm.submit()}
        okText="save"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={tagForm}
          onFinish={handleTagFinish}
          layout="vertical"
          name="form_tag"
        >
          <p className="text-12">
            {tagModal === true
              ? "You are adding new tag to the system. You can use this tag to filter users later on."
              : "You can use this form below to add or edit the user tags that are assigned to this user."}
          </p>
          {tagModal === true ? (
            <>
              <Form.Item
                label="User tag"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "User tag",
                  },
                ]}
              >
                <Input maxLength={250} placeholder="User tag" />
              </Form.Item>
              <p className="tag-list">
                {tagList?.map((item) => (
                  <Tag
                    closable
                    onClose={() => {
                      setTagModal(false);
                      setEditingTag({ ...item, del: true });
                    }}
                    key={item.name}
                  >
                    {item.name}{" "}
                    <EditOutlined
                      onClick={() => {
                        setTagModal(false);
                        setEditingTag({ ...item });
                      }}
                    />
                  </Tag>
                ))}
              </p>
            </>
          ) : (
            <>
              <Form.Item noStyle name="id">
                <Input hidden disabled />
              </Form.Item>
              <Form.Item
                label="User tag"
                name="tags"
                rules={[
                  {
                    // required: true,
                    type: "array",
                    message: "User tag",
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="Select..."
                  mode="multiple"
                >
                  {tagList?.map((item) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Assign user tag"
        className="common-modal admin-user-modal"
        visible={!!bulkTagModal}
        onOk={() => bulkTagForm.submit()}
        okText="add"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setBulkTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={bulkTagForm}
          onFinish={handleBulkTagFinish}
          layout="vertical"
          name="form_tag_bulk"
        >
          <p className="text-12">
            You are assigning these following tags to {selectedRows.length}{" "}
            selected users
          </p>

          <Form.Item
            label="User tag"
            name="tags"
            rules={[
              {
                required: true,
                type: "array",
                message: "User tag",
              },
            ]}
          >
            <Select bordered={false} placeholder="Select..." mode="multiple">
              {tagList?.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Admin note"
        className="common-modal admin-user-modal"
        visible={noteModal}
        onOk={() => noteForm.submit()}
        okText="save changes"
        cancelText="cancel"
        onCancel={() => setNoteModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={noteForm}
          onFinish={handleNoteFinish}
          layout="vertical"
          name="form_note"
        >
          <Form.Item noStyle name="id">
            <Input hidden disabled />
          </Form.Item>
          <div className="flex">
            <Form.Item label="First name" name="firstName">
              <Input disabled maxLength={50} placeholder="First name" />
            </Form.Item>
            <Form.Item label="Last name" name="lastName">
              <Input disabled maxLength={50} placeholder="Last name" />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item label="User role" name="roles">
              <Select
                disabled
                allowClear
                bordered={false}
                placeholder="Select a role "
              >
                {roles.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="User tag" name="tags">
              <Input disabled maxLength={50} placeholder="User tag" />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item label="email" name="email">
              <Input disabled maxLength={100} placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              normalize={(e) => {
                return e.replace(/[^\d\-()]*/g, "");
              }}
            >
              <Input disabled maxLength={50} placeholder="Phone" />
            </Form.Item>
          </div>

          <Form.Item
            className="textarea-wrap"
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "note",
              },
            ]}
          >
            <Input.TextArea placeholder="Note..." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal admin-artist-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search admin note" name="note">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <Form.Item label="user tag" name="tags">
            <Select
              allowClear
              bordered={false}
              placeholder="Select a tag "
              mode="multiple"
            >
              {tagList.map((item) => (
                <Select.Option key={item.id + item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {component}
      {deleteComponent}
      {editEmailComp}
      <Modal
        visible={!!editingTag}
        okText={editingTag?.del ? "delete" : "save"}
        className="common-modal admin-user-modal"
        centered
        width={570}
        closable={false}
        maskClosable={false}
        onCancel={() => setEditingTag(undefined)}
        onOk={saveEditTag}
        title={
          editingTag && !editingTag.del
            ? `Edit user tag [${
                tagList.find((_) => _.id === editingTag.id).name
              }]`
            : undefined
        }
      >
        {editingTag?.del ? (
          <h1>Are you sure you want to delete [{editingTag?.name}]?</h1>
        ) : (
          <Form layout="vertical">
            <Form.Item label="edit tag">
              <Input
                value={editingTag?.name}
                onChange={(e) =>
                  setEditingTag({ ...editingTag!, name: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};
