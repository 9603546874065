import React, { useEffect, useState } from "react";
import { Button, Form, Input, List, Spin, Tag, Tooltip, Upload } from "antd";
import { KV, useModel } from "use-reaction";
import attachment from "../../../images/attachment.svg";
import sendmsg from "../../../images/sendmsg.svg";
import {
  getChannels,
  getMsgList,
  initChanel,
  rmChatMsg,
  sendChatMsg,
} from "@/api/message-api";
import dayjs from "dayjs";
import { localStorageGet, localStorageSet } from "@/utils/storage";
import { UploadFile } from "antd/lib/upload/interface";
import { useParams } from "react-router-dom";
import rmIcon from "../../../images/rm_icon.svg";
import "./myMsg.less";
import { useDebouncedCallback } from "use-debounce/lib";
import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import { user } from "@/model/user";
import { userAccess } from "@/adminApp/apis/user-api";
import { getEnv } from "@/libs/cfg";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

export enum UserRole {
  SUPER_ADMIN = "super_administrator",
  ADMIN = "administrator",
  DONOR = "donor",
  ARTIST = "artist",
  PREMIUM = "premium",
}

interface ChatUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  roles: UserRole[];
}

interface ChatMsg {
  id: number;
  from: ChatUser;
  to: ChatUser;
  type: "link" | "html";
  content: string;
  createAt: string;
  self?: boolean;
  link?: string[]; // if type = link, fill this with link name, link url
  tm: string;
  showAvatar: boolean;
}
interface Channel {
  id: number;
  with: ChatUser;
  latest: ChatMsg & { status: "read" | "unread" }; // lack from / to
}
interface TimeLine {
  timeline: string;
}

const MyMsg = (props: KV) => {
  let { to } = useParams() as any; // if to = undefined, means client send chats to admin, otherwise, means admin send chats to client user
  if (to) to = parseInt(to);
  const [msgs, setMsgs] = useState<ChatMsg[]>([]);
  const [list, setList] = useState<(ChatMsg | TimeLine)[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [chnls, setChnls] = useState<Channel[]>([]);
  const [chnl, setChnl] = useState<Channel>(null as any);

  const [page, setPage] = useState({ idx: 0, hasMore: true });
  const [inputMsg, setInputMsg] = useState("");
  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [tipTrigger, setTipTrigger] = useState(0)
  const {
    store: {
      info: { id: userId },
    },
  } = useModel(user);
  const roles = localStorageGet("roles");

  const isAdminUser = roles?.includes(UserRole.ADMIN);

  const loadMsg = async (pageIdx?: number, clearMsgs?: boolean) => {
    if (loading) return;
    pageIdx = pageIdx === undefined ? page.idx : pageIdx;
    setLoading(true);
    const res = await getMsgList(pageIdx, 16, chnl?.id);
    if (res.msgs?.length) {
      setMsgs(
        (clearMsgs ? [] : msgs).concat(
          res.msgs.map((m: any) => ({
            ...m,
            self: m.from?.id === userId || (isAdminUser && !m.from),
            link: parseLink(m),
          }))
        )
      );
    } else {
      setMsgs([])
    }
    setPage({ idx: res.hasMore ? pageIdx + 1 : pageIdx, hasMore: res.hasMore });
    setLoading(false);
  };

  const loadChnlMsg = () => {
    loadMsg(0, true);
  };

  const loadChannels = async () => {
    let api = getChannels;
    if (to) {
      api = async () => initChanel(to);
    }
    const res = await api();
    if (res?.channels?.length) {
      setChannels(res.channels);
      setChnls(res.channels);
    }
  };

  const filterChnl = (val: string) => {
    if (val) {
      const reg = new RegExp(val, "ig");
      const filterd = channels.filter(
        (c) => reg.test(c.with.firstName) || reg.test(c.with.lastName)
      );
      setChnls(filterd);
    } else {
      setChnls(channels);
    }
  };

  useEffect(() => {
    // for admin to view chats by channels
    if (isAdminUser) {
      if (!channels.length) {
        loadChannels();
      } else if (!chnl) {
        let ch = to ? channels.find((_) => _.with?.id === to) : null;
        ch = ch || channels[0];
        ch.latest.status = "read";

        setChnl(ch);
      } else {
        // list msg of cur chnl
        loadChnlMsg();
      }
    } else {
      loadMsg();
    }
    // eslint-disable-next-line
  }, [channels, chnl]);

  useEffect(() => {
    const temp = [...msgs].reverse();
    const res: any = [];
    let lastFrom;
    for (const m of temp) {
      m.showAvatar = true; // new design, show avatar every row

      // m.showAvatar = false;
      // b/c if from = null/undefined,means msg from admin, so use != instead of !==
      // eslint-disable-next-line
      if (lastFrom != m.from?.id) {
        lastFrom = m.from?.id || null;
        // m.showAvatar = true;
      }

      m.tm = m.tm || dayjs(m.createAt).format("MM/DD/YYYY");
      if (!res[m.tm]) {
        res[m.tm] = true;
        res.push({ timeline: m.tm });
        // m.showAvatar = true; // showavatar after new timeline
      }

      res.push(m);
    }

    setList(res);
  }, [msgs]);

  const scrollToBtm = useDebouncedCallback((latest: number) => {
    const msgNode = document.querySelector(`#myMsg-msg-${latest}`);
    if (msgNode) {
      msgNode.scrollIntoView();
    }
  }, 100);

  const rmAttach = (f: UploadFile) => {
    const list = [...attachments];
    const idx = list.findIndex((_) => _ === f);
    if (idx > -1) {
      list.splice(idx, 1);
      setAttachments(list);
    }
  };
  const parseLink = (msg: ChatMsg) => {
    if (msg.type === "html") {
      return;
    }
    const arr = msg.content.split("](");
    return [arr[0].substr(1), arr[1].substr(0, arr[1].length - 1)];
  };
  const sendMsg = async () => {
    let inputRes;
    const newMsgs = [];
    if(!inputMsg && !attachments.length){
      return
    }
    setLoading(true);
    const urlReg = /(((http|https):\/\/)|(\swww\.))?([\w-]+\.)+[a-z]{2,6}\b([/-a-zA-Z0-9@:%_+.~#?&=]*)/gi
    if (inputMsg) {
      inputRes = await sendChatMsg({
        to: chnl?.with?.id || to,
        type: "html",
        content: `<span>${inputMsg.trim().replace(/\n/g, '<br/>').replace(urlReg, `<a href="$&" target="_blank">$&</a>`)}</span>`,
      });
      setInputMsg("");
    }
    if (attachments.length) {
      // send attachments
      for (const fl of attachments) {
        const file = fl.originFileObj || fl;
        const name = fl.name;
        const data = await new ((window as any)?.AWS).S3()
          .upload({
            Bucket: process.env.REACT_APP_AWS_BUCKET,
            Key: `chat_attachment/${fl.type}_${Date.now()}_${name}`,
            Body: file,
          })
          .promise();
        if (data.Location) {
          const linkRes = await sendChatMsg({
            to: chnl?.with?.id,
            type: "link",
            content: `[${name}](${data.Location})`,
          });
          linkRes?.id && newMsgs.push(linkRes);
        }
      }
      setAttachments([]);
    }
    inputRes?.id && newMsgs.push(inputRes);
    newMsgs.forEach((m) => {
      m.self = true;
      m.link = parseLink(m);
    });
    setMsgs([...newMsgs, ...msgs]);
    scrollToBtm(newMsgs[0].id);
    setLoading(false);
    setTipTrigger(tipTrigger + 1)
  };

  const rmMsg = async (msg: ChatMsg) => {
    setLoading(true);
    const res = await rmChatMsg(msg.id);
    if (res?.success) {
      if (msg.type === "link") {
        await new Promise((_) => {
          new ((window as any)?.AWS).S3().deleteObject(
            {
              Bucket: process.env.REACT_APP_AWS_BUCKET,
              Key: msg.link![1].split("/").pop(),
            },
            function (err: any, data: any) {
              if (err) {
                // messageThe Field Admin
                _(false);
              } else {
                _(true);
              }
            }
          );
        });
      }

      // update ui
      const old = [...msgs];
      const idx = old.findIndex((_) => _ === msg);
      if (idx > -1) {
        old.splice(idx, 1);
        setMsgs(old);
      }
    }
    setLoading(false);
  };

  const handleAccessToUser = (id: number) => {
    userAccess({ id }).then((data) => {
      window.open(`${getEnv("WEB_HOST")}/auth/${data.token}`, "_blank");
    });
  };

  return (
    <div className="my-message-con">
      {channels.length > 0 && (
        <div className="my-channels">
          <Input
            prefix={<SearchOutlined />}
            className="name-filter"
            placeholder="search"
            allowClear
            onChange={(e) => filterChnl(e.target.value)}
          />
          <List
            dataSource={chnls}
            renderItem={(ch) => (
              <div
                className={`channel ${ch === chnl ? "selected" : ""}`}
                onClick={(e) => {
                  ch.latest.status = "read";
                  setChnl(ch);
                }}
              >
                {/* <div className="avatar">
                  {ch.with.avatar ? (
                    <img src={ch.with.avatar} alt="avatar" />
                  ) : (
                    <span>TF</span>
                  )}
                </div> */}
                <div className={`summary ${ch.latest.status}`}>
                  <div className="title">{`${ch.with.firstName} ${ch.with.lastName}`}</div>
                  <div className="content">
                    {/* <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: ch.latest.content }}
                    ></div> */}
                    {/* <div className="time">
                      {dayjs(ch.latest.createAt).format("h:mm A")}
                    </div> */}
                  </div>
                  <span className="red-dot" hidden={ch.latest.status === 'read'}></span>
                </div>
              </div>
            )}
          />
        </div>
      )}
      <div className="my-message">
        <AutoTip isAdminUser={isAdminUser} trigger={tipTrigger} />
        <h2 className="title">
          {chnl?.with
            ? <><NoTranslate>{chnl.with.firstName} {chnl.with.lastName}</NoTranslate></>
            : <><TheField/> Admin</>}
          {
            isAdminUser && chnl?.with && <ExportOutlined style={{ marginLeft: 20 }} title="access account" onClick={e => handleAccessToUser(chnl.with.id)} />
          }
        </h2>
        <div className="msg-panel">
          <Spin spinning={loading}>
            {page.hasMore && (
              <div className="load-more" onClick={(e) => loadMsg()}>
                <span>click to load earlier messages</span>
              </div>
            )}
            {list.map((m, idx) =>
              (m as any).timeline ? (
                <TimelineItem
                  timeline={(m as TimeLine).timeline}
                  key={`chat-msg-${(m as any).timeline}-${idx}`}
                />
              ) : (
                <ChatItem
                  msg={m as ChatMsg}
                  rmMsg={rmMsg}
                  key={`timeline-${(m as any).id}`}
                  handleAccessToUser={isAdminUser ? handleAccessToUser : undefined}
                />
              )
            )}
          </Spin>
        </div>
        <Form onFinish={sendMsg} className="input-panel">
          <Input.TextArea
            className="input-field"
            placeholder="Write a message..."
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            onPressEnter={e => {
              if (!e.shiftKey) {
                sendMsg()
              }
            }}
          />
          <Upload
            showUploadList={false}
            multiple
            fileList={attachments}
            beforeUpload={(file) => {
              setAttachments([...attachments, file as any]);
              return false;
            }}
          >
            <Button type="text" className="attach">
              <img src={attachment} alt="attachment" />
            </Button>
          </Upload>
          <Button
            type="primary"
            className="send"
            disabled={loading}
            htmlType="submit"
            icon={<img src={sendmsg} alt="send" />}
          >
            &nbsp;Send
          </Button>
        </Form>
        <div className="attach-panel">
          {attachments.map((a, idx) => (
            <Tag
              key={`attach-tag-${idx}`}
              closable={!loading}
              onClose={(e) => rmAttach(a)}
            >
              {a.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

const TimelineItem = ({ timeline }: TimeLine) => {
  return (
    <div className="timeline">
      <span>{timeline}</span>
    </div>
  );
};

const ChatItem = ({
  msg,
  rmMsg,
  handleAccessToUser,
}: {
  msg: ChatMsg;
  rmMsg: (msg: ChatMsg) => void;
  handleAccessToUser?: (id: number) => void;
}) => {
  const isAdmin = !msg.from || msg.from?.roles?.includes(UserRole.ADMIN)

  const header = (<div className="avatar">
    {msg.from?.avatar ? (
      <img src={msg.from.avatar} alt="avatar" />
    ) : (
      <span>
        {(isAdmin
          ? "TF"
          : msg.from?.firstName
        ).substr(0, 2) || "TF"}
      </span>
    )}
  </div>)

  return (
    <div
      className={`chat-msg ${msg.self ? "right" : ""}`}
      id={`myMsg-msg-${msg.id}`}
    >
      {msg.showAvatar && (
        handleAccessToUser ?
          <Tooltip overlay={isAdmin ? '' : <div onClick={e => handleAccessToUser(msg.from.id)} style={{cursor: 'pointer'}}><ExportOutlined style={{ margin: 6, fontSize: 18 }} /> Access &nbsp;</div>}>
            {header}
          </Tooltip> : <>{header}</>
      )}
      {getContent(msg, rmMsg)}
    </div>
  );
};

const AutoTip = ({ isAdminUser = false, trigger = 0 }) => {
  const notShow = () => {
    localStorageSet('chat-hide-tip', true)
  }
  const enable = !(isAdminUser || localStorageGet('chat-hide-tip'))
  const [showing, setShowing] = useState(0)
  useEffect(() => {
    if (!trigger || !enable) {
      return
    }
    showing && clearTimeout(showing)
    const id = window.setTimeout(() => {
      setShowing(0)
    }, 5000)
    setShowing(id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])
  return <div className={`auto-tip ${showing ? 'show' : ''}`}>
    <h3>AUTO-RESPONSE:</h3>
    <br />
    <span>Thank you for reaching out! Your message is being reviewed by our artist services team.</span>
    <br />
    <span>Please stand by for a response.</span>
    <i onClick={notShow}>Click here</i>
    <span>to stop seeing this message.</span>
  </div>
}
const getContent = (msg: ChatMsg, rmMsg: (msg: ChatMsg) => void) => {
  const content =
    msg.type === "link" ? (
      <div className="content link">
        <a href={msg.link![1]}>{msg.link![0]}</a>
      </div>
    ) : (
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: msg.content }}
      ></div>
    );
  return msg.self ? (
    <Tooltip
      placement={msg.self ? "leftTop" : "rightTop"}
      title={
        <div
          style={{ cursor: "pointer", padding: "10px" }}
          onClick={(e) => rmMsg(msg)}
        >
          <img src={rmIcon} alt="rm_icon" />
          <span style={{ color: "#000000", paddingLeft: "7px" }}>Remove</span>
        </div>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default MyMsg;
