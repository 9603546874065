import {
  Form,
  Input,
  Button,
  Tooltip,
  Menu,
  message,
  Breadcrumb,
  Tag,
  Select,
  Divider,
  Switch,
  Radio,
  Space,
} from "antd";
import {
  CloudUploadOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import "./sponsorship_review.less";
import "@/assets/common.less";

import { useHistory, useParams } from "react-router-dom";
import { admin_router_message_to } from "@/adminApp/routers";
import {
  editSponsorInfo,
  getSponsorDetail,
  reviewSponsorship,
} from "@/adminApp/apis/user-api";
import { KV } from "use-reaction";
import { flatten, unflatten } from "@/utils/util";
import { membershipStatus } from "@/types/enum";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import ModalInfo from "@/components/Modal/Info";
import icon_donation from "@/images/icons/icon_donation.svg";
import icon_warning from "@/images/icons/icon_warning.svg";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import SelectAndInput from "@/components/Select/SelectAndInput";
import _ from "lodash";
import {
  deleteDocument,
  getUserContracts,
  saveDocument,
} from "@/api/document-api";

export enum SponsorshipStatus {
  NO_APPLY = "no_apply",
  IN_REVIEW = "in_review",
  APPROVED = "approved",
  REJECTED = "rejected",
  PAID = "paid",
  COMPLETED = "completed",
  INCOMPLETE = "incomplete",
}
type ArtistInfo = {
  disciplines: string[];
  website: string;
  biography: string;
  missionAndGoals: string;
};

type PersonInfo = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string[];
};
type SponsorInfo = {
  id: number;
  artistInformation?: ArtistInfo;
  personalInformation?: PersonInfo;
  signatureInfo?: KV;
  status?: SponsorshipStatus;
  note?: string;
  userId?: number;
};
const initInfo: SponsorInfo = {
  id: 0,
};

export const SponsorshipReview = () => {
  let { id } = useParams() as any;
  id = parseInt(id);
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [allowSubsidized, setAllowSubsidized] = useState(false);
  const [subsidizedShow, setSubsidizedShow] = useState(false);
  const [info, setInfo] = useState<any>(initInfo);
  const [contractsOld, setContractsOld] = useState<any>();
  const [contracts, setContracts] = useState<any>();
  const [formRef] = Form.useForm();
  const [applySubsidized, setApplySubsidized] = useState("");

  const loadInfo = async () => {
    const res = await getSponsorDetail(id);
    if (res?.id) {
      setInfo(res);
      setAllowSubsidized(res?.allowSubsidized || false);
      setApplySubsidized(
        res?.artistInformation?.applySubsidized ? "yes" : "no"
      );
      const disciplines = res.artistInformation?.disciplines;
      delete res.artistInformation?.artistInformation;
      const data = flatten({ ...res });
      data["artistInformation.disciplines"] = disciplines;
      data["artistInformation.disciplines"] = disciplines;
      data["artistInformation.applySubsidized"] = res?.artistInformation
        ?.applySubsidized
        ? "yes"
        : "no";
      data["artistInformation.previouslySponsored"] = res?.artistInformation
        ?.previouslySponsored || "No";
      formRef.setFieldsValue(data);
      // fetch contracts
      const { docs = [] } = await getUserContracts(res.userId);
      setContractsOld(docs);
      setContracts(
        docs.map((d: { id: number; url: string }) => ({
          id: d.id,
          uid: d.id,
          url: d.url,
          status: "done",
          name: d.url.split("/").pop(),
        }))
      );
    }
  };
  const onFinish = async (data: any) => {
    data["artistInformation.applySubsidized"] =
      applySubsidized === "yes" ? true : false;
    const req = unflatten(data);
    if (contractsOld?.length || contracts?.length) {
      // upload contract file
      const kept = _.intersectionBy(contractsOld, contracts, "id");
      const add = _.pullAllBy([...contracts], kept, "id");
      const del = _.pullAllBy([...contractsOld], kept, "id");
      // delete
      for (const d of del) {
        await deleteDocument({ id: d.id, docType: "doc" });
      }
      // add
      add.length &&
        (await saveDocument({
          userId: info.userId,
          url: add.map((a) => a.url).join(","),
          sendContractNote: true,
          type: "Sponsorship Agreement", // hardcode from BE
        }));
    }
    const res = await editSponsorInfo(id, req);
    if (res?.success) {
      message.success("save succeed!");
      setIsEdit(false);
      loadInfo();
    }
  };

  const messageTo = async () => {
    history.push(admin_router_message_to.replace(":to", info.userId + ""));
  };

  const review = async (
    status: SponsorshipStatus,
    allowSubsidized?: boolean
  ) => {
    const res = await reviewSponsorship(id, status, allowSubsidized);
    if (res?.success) {
      message.info(`${status} succeed!`);
      loadInfo();
      setTimeout(() => {
        history.push("/application/membership");
      }, 500);
    }
  };

  const onSubsidizedChange = (checked: boolean) => {
    setAllowSubsidized(checked);
  };

  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="sponsorship-review">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/application/membership">
          Fiscal Sponsorship
        </Breadcrumb.Item>
        <Breadcrumb.Item>View application</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex start top-bar">
        <h2 className="title">
          {info.personalInformation?.firstName}{" "}
          {info.personalInformation?.lastName}
        </h2>
        <Tag
          className="status-tag"
          color={
            membershipStatus[info?.status]
              ? membershipStatus[info?.status]?.color
              : "gray"
          }
        >
          {membershipStatus[info?.status]
            ? membershipStatus[info?.status]?.text
            : info?.status}
        </Tag>
        <Tooltip
          className="action-tool"
          placement="bottomLeft"
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key === "edit") {
                  setIsEdit(true);
                } else {
                  messageTo();
                }
              }}
            >
              <Menu.Item key="message">Send message</Menu.Item>
              <Menu.Item key="edit">Edit</Menu.Item>
            </Menu>
          }
        >
          <div className="action">
            <FilterMore />
          </div>
        </Tooltip>
      </div>
      <div className="des">
        In order to publish and event, you will have to submit the content for
        The Field to review. The approval process will take no more than 5
        business days
      </div>

      <div className={`review-card-container ${!isEdit ? "show" : "hidden"}`}>
        <div className="card-wrap">
          <h2 className="sub-title">Sponsored artist information</h2>
          <p className="flex">
            Artist Name
            <span>
              {info.personalInformation?.firstName}{" "}
              {info.personalInformation?.lastName}
            </span>
          </p>
          <p className="flex">
            Legal Name
            <span>{info.signatureInfo?.legalName}</span>
          </p>
          <p className="flex">
            Associated Names
            <span>{info.signatureInfo?.associatedNames}</span>
          </p>
          <p className="flex">
            Company
            <span>{info.personalInformation?.companyName}</span>
          </p>
          <p className="flex">
            Email address
            <span>{info.personalInformation?.email}</span>
          </p>
          <p className="flex">
            Address
            <span>{info.signatureInfo?.address1}</span>
          </p>
          <p className="flex">
            Phone number
            <span>{info.personalInformation?.phone[0]}</span>
          </p>
          <p className="flex">
            Alternate phone number
            <span>
              {info.personalInformation?.phone.length > 1
                ? info.personalInformation?.phone[1]
                : "-"}
            </span>
          </p>
          <p className="flex">
            Primary Discipline
            <span> {info?.artistInformation?.primaryTag || "N/A"}</span>
          </p>
          <p className="flex">
            Discipline
            <span> {info?.artistInformation?.disciplines?.join(",")}</span>
          </p>
          <p className="flex">
            Website
            <span>{info?.artistInformation?.website}</span>
          </p>
          <p className="flex">
            SSN
            <span>{info?.signatureInfo?.socialSecurityNumber}</span>
          </p>
          <p className="flex">
            EIN
            <span>{info?.signatureInfo?.website || "-"}</span>
          </p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Artist biography</h2>
          <p>{info?.artistInformation?.biography}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Artist practice and current projects</h2>
          <p>{info?.artistInformation?.practice}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Mission and Goals</h2>
          <p>{info?.artistInformation?.missionAndGoals}</p>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">
            <div className="sub-title-wrap">
              Subsidized Fiscal Sponsorship for Social Justice Arts
              Practitioners
              <Tag
                className="status-tag"
                color={
                  info?.artistInformation?.applySubsidized ? "green" : "red"
                }
              >
                {info?.artistInformation?.applySubsidized ? "Yes" : "No"}
              </Tag>
            </div>
          </h2>
          {info?.artistInformation?.applySubsidized && (
            <div className="subsidized-wrap-container">
              <div className="subsidized-wrap">
                <div className="subsidized-title">Have you been previously sponsored by The Field? &nbsp; &nbsp;<Tag
                className="status-tag"
                color={
                  info?.artistInformation?.previouslySponsored === 'Yes' ? "green" : "red"
                }
              >
                {info?.artistInformation?.previouslySponsored}
              </Tag></div>
              </div>
              <Divider />
              {info?.artistInformation?.previouslySponsored === 'Yes' && <><div className="subsidized-wrap">
                <div className="subsidized-title">Please explain how your artistic practice has changed since then. </div>
                <p>{info?.artistInformation?.previouslySponsoredExplain}</p>
              </div>
              <Divider /></>}
              
              <div className="subsidized-wrap">
                <div className="subsidized-title">Value Alignment</div>
                <div className="subsidized-sub-title">
                  Describe how your creative work and mission aligns with The
                  Field's Social Justice Values. How are you putting these
                  values into practice?
                </div>
                <p>{info?.artistInformation?.valueAlignment}</p>
              </div>
              <Divider />
              <div className="subsidized-wrap">
                <div className="subsidized-title">Benefit</div>
                <div className="subsidized-sub-title">
                  Describe how you would benefit from a complimentary year of
                  Membership with Fiscal Sponsorship, a free consultation and
                  any of the other services The Field offers. For reference,
                  read through the benefits offered by The Field’s Memberships
                  and the article in our Knowledge Base, Fiscal Sponsorship For
                  Artists: An Overview.
                </div>
                <p>{info?.artistInformation?.benefit}</p>
              </div>
              <Divider />
              <div className="subsidized-wrap">
                <div className="subsidized-title">
                  <div className="title-left">
                    Approve Subsidized Fiscal Sponsorship
                  </div>
                  <Switch
                    disabled={info?.status !== "in_review"}
                    checked={allowSubsidized}
                    onChange={onSubsidizedChange}
                  />
                </div>

                <div className="subsidized-label">
                  If selected, applicant will be approved for subsidized fiscal
                  sponsorship and payment will be waived.
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Admin note</h2>
          <p>{info?.note}</p>
        </div>
      </div>

      <Form
        form={formRef}
        onFinish={onFinish}
        layout="vertical"
        className={`${isEdit ? "show" : "hidden"}`}
      >
        <h3 className="sub-title">Applicant information</h3>
        <Form.Item label="First name" name="personalInformation.firstName">
          <Input placeholder="Enter First name" disabled />
        </Form.Item>
        <Form.Item label="Last name" name="personalInformation.lastName">
          <Input placeholder="Enter Last name" disabled />
        </Form.Item>
        <Form.Item label="Company name" name="personalInformation.companyName">
          <Input placeholder="Enter Company name" disabled />
        </Form.Item>
        <Form.Item label="Email address" name="personalInformation.email">
          <Input placeholder="Enter Email address" disabled />
        </Form.Item>
        <div className="row flex">
          <Form.Item label="Phone number" name="personalInformation.phone.[0]">
            <Input placeholder="Enter Phone number" disabled />
          </Form.Item>
          <Form.Item
            label="Alternate phone number"
            name="personalInformation.phone.[1]"
          >
            <Input placeholder="Enter alternate phone number" disabled />
          </Form.Item>
        </div>
        <Form.Item
          label={
            <div>
              Disciplines
              <Tooltip
                className="tooltip-field-item"
                title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
              >
                <QuestionCircleFilled />
              </Tooltip>
            </div>
          }
          name="artistInformation.disciplines"
          rules={[
            {
              required: false,
              type: "array",
              message: "Please select your disciplines",
            },
          ]}
        >
          <SelectAndInput disabled />
        </Form.Item>
        <Form.Item label="Website" name="artistInformation.website">
          <Input placeholder="Enter Website" disabled />
        </Form.Item>
        <h3 className="sub-title">Artist biography</h3>

        <Form.Item
          label="Biography"
          name="artistInformation.biography"
          className="textarea-wrap"
        >
          <TextArea placeholder="Enter biography" />
        </Form.Item>
        <h3 className="sub-title">Practice and projects</h3>

        <Form.Item
          label="PracticeAndProjects"
          name="artistInformation.practice"
          className="textarea-wrap"
        >
          <TextArea placeholder="Enter practice and current projects" />
        </Form.Item>
        <h3 className="sub-title">Mission and Goals</h3>

        <Form.Item
          label="MissionAndGoals"
          name="artistInformation.missionAndGoals"
          className="textarea-wrap"
        >
          <TextArea placeholder="Enter missionAndGoals" />
        </Form.Item>

        {/* <h3 className="sub-title">
          Subsidized Fiscal Sponsorship for Social Justice Arts Practitioners
        </h3>
        <Form.Item
          name="artistInformation.applySubsidized"
          label="Are you interested in applying for a free first year of fiscal sponsorship under our
              Subsidized Fiscal Sponsorship program?"
          className="radio-wrap"
        >
          <Radio.Group
            className="radio-selection"
            onChange={(e) => setApplySubsidized(e.target.value)}
          >
            <Space direction="vertical">
              <Radio value="yes">Yes, please</Radio>
              <Radio value="no">
                No, thanks, I’ll continue with the standard application.
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {applySubsidized === "yes" && (
          <div>
            <Form.Item
              name="artistInformation.previouslySponsored"
              label='Have you been previously sponsored by The Field?'
              className="radio-wrap"
              rules={[
                {
                  required: true,
                  whitespace: true
                }
              ]}
            >
              <Radio.Group className="radio-selection" >
                <Space direction="vertical">
                  <Radio value='Yes'>Yes</Radio>
                  <Radio value='No'>No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle dependencies={["artistInformation.previouslySponsored"]}>
              {()=>{
                const temp = formRef.getFieldValue("artistInformation.previouslySponsored");
                if(temp === 'Yes'){
                  return <Form.Item
                  name="artistInformation.previouslySponsoredExplain"
                  label='Please explain how your artistic practice has changed since then.'
                  className="textarea-wrap"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please explain how your artistic practice has changed since then. ",
                    },
                    {
                      validator: (_, value) => {
                        if (value?.split(/\s+/g)?.length > 300) {
                          return Promise.reject(
                            new Error("Explain is limited to 300 words")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                      validateTrigger: "blur",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    maxLength={2000}
                    placeholder="Please explain how your artistic practice has changed since then. "
                  />
                </Form.Item>
                }
              }}
            </Form.Item>
            <Form.Item
              name="artistInformation.valueAlignment"
              label="Value Alignment"
              className="textarea-wrap"
            >
              <Input.TextArea
                className="textarea-wrap-length-medium"
                rows={4}
                maxLength={2000}
                placeholder="Describe how your creative work and mission aligns with The Field's Social Justice Values. How are you putting these values into practice?"
              />
            </Form.Item>
            <Form.Item
              name="artistInformation.benefit"
              label="benefit"
              className="textarea-wrap"
            >
              <Input.TextArea
                rows={4}
                maxLength={2000}
                placeholder="Describe how you would benefit from a complimentary year of Membership with Fiscal Sponsorship, a free consultation and any of the other services The Field offers. For reference, read through the benefits offered by The Field’s Memberships and the article in our Knowledge Base, Fiscal Sponsorship For Artists: An Overview."
              />
            </Form.Item>
          </div>
        )} */}
        <Form.Item label="Admin Note" className="textarea-wrap" name="note">
          <TextArea placeholder="Enter admin note" />
        </Form.Item>

        <Form.Item label="Sponsorship Status" className="textarea-wrap" name="status">
          <Select bordered={false}>
            <Select.Option key="in_review" value="in_review">
              In Review
            </Select.Option>
            <Select.Option key="approved" value="approved">
              Approved
            </Select.Option>
            <Select.Option key="rejected" value="rejected">
              Rejected
            </Select.Option>
            <Select.Option key="paid" value="paid">
              Fee Paid
            </Select.Option>
            <Select.Option key="wait_for_signature" value="wait_for_signature">
              Waiting for Signature
            </Select.Option>
            <Select.Option key="completed" value="completed">
              Active
            </Select.Option>
            <Select.Option key="expired" value="expired">
              Expired
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
      {isEdit && (
        <div className="contracts">
          <h3 className="sub-title">Contract</h3>
          <UploadCrop
            crop={false}
            accept=".pdf,.xls,.xlsx,.doc,.docx,.png,.jpeg,.ics"
            listType="picture"
            className="upload-item-wrap"
            showUploadList
            maxCount={10}
            value={contracts}
            onChange={setContracts}
          >
            <div className="children-upload">
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload 1 file at a time. We support .pdf,.xls, .xlsx, .doc,
                .docx, .png, .jpeg, .ics. File size should be under 100MB
              </p>
            </div>
          </UploadCrop>
        </div>
      )}
      <div className="row flex end">
        {isEdit ? (
          <Button
            onClick={() => formRef.submit()}
            type="primary"
            className="btn-draft"
          >
            Save Changes
          </Button>
        ) : info?.status === "in_review" ? (
          <>
            <Button
              className="btn-reject"
              onClick={() => review(SponsorshipStatus.REJECTED)}
            >
              Decline
            </Button>
            <Button
              type="primary"
              className="btn-approve"
              onClick={(e) => {
                applySubsidized === "yes"
                  ? setSubsidizedShow(true)
                  : review(SponsorshipStatus.APPROVED);
              }}
            >
              Approve
            </Button>
          </>
        ) : (
          ""
        )}
      </div>

      <ModalInfo
        visible={subsidizedShow}
        onClose={() => {
          setSubsidizedShow(false);
        }}
        footer={[
          <Button
            onClick={() => {
              setSubsidizedShow(false);
            }}
          >
            Wait, Go back
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setSubsidizedShow(false);
              review(SponsorshipStatus.APPROVED, allowSubsidized);
            }}
          >
            {allowSubsidized ? "Yes, approve" : "Yes, Proceed"}
          </Button>,
        ]}
        img={allowSubsidized ? icon_donation : icon_warning}
      >
        <h4>
          {allowSubsidized
            ? "Approving Subsidized Fiscal Sponsorship"
            : "Rejecting Subsidized Fiscal Sponsorship"}
        </h4>
        <p>
          {allowSubsidized
            ? "This applicant will be approved for Subsidized Fiscal Sponsorship!"
            : "This applicant will not be approved for subsidized fiscal sponsorship, however they will be approved for standard fiscal sponsorship."}
        </p>
      </ModalInfo>
    </div>
  );
};
